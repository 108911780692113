import { Heading, Main } from "grommet";
import React from "react";

export const AboutPage = () => {
  return (
    <Main pad="large">
      <Heading size={"small"}>How to play Annaquiz</Heading>
    </Main>
  );
};
